.container {
  padding: 1em;
  margin-bottom: 5em;
}

.container>p {
  text-align: justify;
}

.container>h2 {
  margin-top: 1em !important;
}

.container>p {
  margin-top: 0.5em;
}

.container li {
  margin-left: 1.5em;
}