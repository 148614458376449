.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  text-align: center;
  color: #000;
  height: calc(100vh - 3em);
}

p {
  z-index: 1;
}

.LoginButton {
  margin-top: calc(45vh + 5vw);
}

.SignUpButton {
  margin-top: calc(15px);
}

.WelcomeText {
  margin-top: calc(7vh + 15vw);
}

.Ellipse1 {
  position: absolute;
  width: 130vw;
  height: 45vh;
  top: 5em;

  border-radius: 50%;
  background: #2BC094;
  transform: rotate(-15deg);
}

.Frog {
  position: absolute;
  height: 70vh;
  top: 0;
}

.Ellipse2 {
  box-sizing: border-box;

  position: absolute;
  width: 80vw;
  height: 25vh;
  top: 50vh;

  border-radius: 50%;
  background: #1A9F87;
  border: 1px solid #000000;
  transform: rotate(15deg);
}