.container {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  align-items: center;
  border-radius: 15px;
  border: 2px solid #ddd;
  margin: 0.5em;
  overflow: hidden;
}

.container > img {
  width: 10em;
  margin-right: 0.5em;
}

.container > div > p:last-child {
  margin-top: 2em;
}

.latin {
  color: #999;
}