.container {
  padding: 1em;
  margin-bottom: 5em;
}

.container > ul {
  list-style-type: none;
}

.container > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container > div > * {
  margin-right: 0.5em;
}