.container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  align-items: start;
  border-radius: 15px;
  border: 2px solid #ddd;
  margin: 0.5em;
  padding: 10px;
}

.container > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container > div > h2 {
  margin: 0 5px;
}
