.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  width: 100vw;
}

.statContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
  width: 100%;
  padding: 1em;
}

.statContainer > * {
  width: 50vw;
}

.allButton {
  width: 65vw;
  font-size: 1.25em;
  margin: 0.5em;
}